import React, { useEffect, useState } from "react";
import { Error, Result } from "../../../types/ZippsID";
import styles from "./ZippsID.module.scss";

interface Props {
  onError: (error: Error) => void;
  onSuccess: (result: Result) => void;
}

const ZippsID = ({ onError, onSuccess }: Props) => {
  const [loading, setLoading] = useState(true);

  const handleReady = () => setLoading(false);
  const handleError = ({ detail }: CustomEventInit<Error>) => detail && onError(detail);
  const handleSuccess = ({ detail }: CustomEventInit<Result>) => detail && onSuccess(detail);

  useEffect(() => {
    window.addEventListener("ready", handleReady);
    window.addEventListener("error", handleError);
    window.addEventListener("success", handleSuccess);

    return (() => {
      window.removeEventListener("ready", handleReady);
      window.removeEventListener("error", handleError);
      window.removeEventListener("success", handleSuccess);
    })
  }, []);

  return (
    <div className={styles.container}>
      {loading && (
        <>
          <div
            className={`spinner-border text-primary ${styles.spinner}`}
            role="status"
          />
          <p className={styles.text}>
            Cargando
          </p>
        </>
      )}
      <zipps-id />
    </div>
  )
};

export default ZippsID;